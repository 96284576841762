/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { SVGProps } from 'react';

const IconArrow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" {...props}>
    <path
      fill="#141929"
      d="M7.678 12l-.985-.972 4.053-4.052H.25V5.57h10.496L6.693 1.53l.985-.985 5.727 5.728z"
    ></path>
  </svg>
);

export { IconArrow };
