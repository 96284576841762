/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme } from '@material-ui/core';
import React from 'react';

import { Breakpoints } from 'utils/enums';

const CarouselDot = styled(({ isActive: _, ...p }) => <button {...p} />)<
  Theme,
  { isActive: boolean }
>(({ theme, isActive }) => {
  const mainBrand = theme.palette.primary.main;

  return {
    background: isActive ? mainBrand : 'none',
    border: 0,
    borderRadius: theme.borderRadius[2],
    color: isActive ? theme.palette.getContrastText(mainBrand) : mainBrand,
    cursor: 'pointer',
    outline: 'none',
    padding: theme.spacing(0.75, 5),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
    }),

    [theme.breakpoints.up(Breakpoints.Md)]: {
      padding: theme.spacing(1.5, 2),
    },
  };
});

const DotContainer = styled('div')(({ theme }) => ({
  bottom: theme.spacing(-15),
  display: 'flex',
  position: 'absolute',
  right: 0,
  top: 'unset',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    bottom: 0,
    top: 0,
    width: 'unset',
  },
}));

const DotWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: theme.borderRadius[2],
  display: 'inline-flex',
  flexDirection: 'row',
  margin: 'auto',
  padding: 2,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'column',
    transform: 'translateY(-100px)',
  },
}));

const CarouselDotContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <DotContainer>
      <DotWrapper>{children}</DotWrapper>
    </DotContainer>
  );
};

export { CarouselDot, CarouselDotContainer };
