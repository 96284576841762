import React from 'react';

import { ApolloWrapper } from './apollo/ApolloWrapper';
import { AlertProvider, Provider } from './components/UI';
import { OptimizelyProvider } from './optimizely';

/**
 * Wrapper that goes around the rest of Runway. Contains providers.
 *
 * @param {*} { element }
 */
/* eslint-disable-next-line react/prop-types */
const Wrapper = ({ element }) => (
  <Provider>
    <ApolloWrapper>
      <OptimizelyProvider>
        <AlertProvider>{element}</AlertProvider>
      </OptimizelyProvider>
    </ApolloWrapper>
  </Provider>
);

export { Wrapper };
