/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { SVGProps } from 'react';

const IconPlus: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width={12} height={12} {...props}>
    <g fill="none" fillRule="evenodd" strokeWidth="1">
      <g transform="translate(-1364 -400)">
        <g transform="translate(648 203)">
          <g id="a" transform="translate(0 191)">
            <g transform="translate(712 2)">
              <g>
                <rect width="20" height="20" />
                <line
                  transform="translate(10 10) rotate(-90) translate(-10 -10)"
                  x1="10"
                  x2="10"
                  y1="4"
                  y2="16"
                  stroke="#141929"
                  strokeWidth="1.4"
                />
                <line
                  x1="10"
                  x2="10"
                  y1="4"
                  y2="16"
                  stroke="#141929"
                  className="whileOpenRemove"
                  strokeWidth="1.4"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { IconPlus };
