/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormControl } from '@material-ui/core';
import React from 'react';

const Form: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <FormControl>{children}</FormControl>;
};

export { Form };
