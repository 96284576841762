/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

// Default
const AF_BETA_ORIGIN_DEFAULT = 'http://localhost:8080';
const RUNWAY_ORIGIN_DEFAULT = 'http://localhost';
const TERMINAL_GRAPH_API_URL_DEFAULT = 'http://localhost:3000/graphql';
const OPTIMIZELY_SDK_KEY_DEFAULT = '26jSzo6CBycJjm9EDZz5Z';
const INTERCOM_APP_ID_DEFAULT = 'kypi33yy';
const GOOGLE_ANALYTICS_ID_DEFAULT = 'G-M07M8Z6PZV';
const POSTHOG_API_KEY_DEFAULT =
  'phc_NRMDkAhP3bkwETbUmn9nxlZ67QoJszlOyZvpl6ArD59';
const TINYURL_API_KEY_DEFAULT =
  'RU2ziQ8tNp1Hma4jdwqA7JjbwNNi5eQtuq0LBKezVJZ6nevgNcLOWNDQYkMC';
const RECAPTCHA_SITE_KEY_DEFAULT = '6LcASVYpAAAAAFuLu2PJKB4UqUK01ct7Uxir6DQ7';

// Variables
const NODE_ENV = process.env.NODE_ENV;
const AF_ENV = process.env.AF_ENV || 'development';
const AF_VERSION = process.env.AF_VERSION;
const AF_BETA_ORIGIN = process.env.AF_BETA_ORIGIN || AF_BETA_ORIGIN_DEFAULT;
const RUNWAY_ORIGIN = process.env.RUNWAY_ORIGIN || RUNWAY_ORIGIN_DEFAULT;
const TERMINAL_GRAPH_API_URL =
  process.env.TERMINAL_GRAPH_API_URL || TERMINAL_GRAPH_API_URL_DEFAULT;
const STRIPE_PUBLIC_KEY = process.env.STRIPE_PUBLIC_KEY;
const OPTIMIZELY_SDK_KEY =
  process.env.OPTIMIZELY_SDK_KEY || OPTIMIZELY_SDK_KEY_DEFAULT;
const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || INTERCOM_APP_ID_DEFAULT;
const GOOGLE_ANALYTICS_ID =
  process.env.GOOGLE_ANALYTICS_ID || GOOGLE_ANALYTICS_ID_DEFAULT;
const POSTHOG_API_KEY = process.env.POSTHOG_API_KEY || POSTHOG_API_KEY_DEFAULT;
const TINYURL_API_KEY = process.env.TINYURL_API_KEY || TINYURL_API_KEY_DEFAULT;
const RECAPTCHA_SITE_KEY =
  process.env.RECAPTCHA_SITE_KEY || RECAPTCHA_SITE_KEY_DEFAULT;

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log('CONSTANTS', {
    AF_BETA_ORIGIN,
    AF_ENV,
    AF_VERSION,
    GOOGLE_ANALYTICS_ID,
    INTERCOM_APP_ID,
    NODE_ENV,
    OPTIMIZELY_SDK_KEY,
    POSTHOG_API_KEY,
    RECAPTCHA_SITE_KEY,
    RUNWAY_ORIGIN,
    STRIPE_PUBLIC_KEY,
    TERMINAL_GRAPH_API_URL,
    TINYURL_API_KEY,
  });
}

const isProduction = AF_ENV === 'production';

export {
  AF_BETA_ORIGIN,
  AF_ENV,
  AF_VERSION,
  GOOGLE_ANALYTICS_ID,
  INTERCOM_APP_ID,
  isProduction,
  NODE_ENV,
  OPTIMIZELY_SDK_KEY,
  POSTHOG_API_KEY,
  RECAPTCHA_SITE_KEY,
  RUNWAY_ORIGIN,
  STRIPE_PUBLIC_KEY,
  TERMINAL_GRAPH_API_URL,
  TINYURL_API_KEY,
};
