/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { createElement, MouseEvent } from 'react';
import { isValidElementType } from 'react-is';

// Types

interface ActionableProps {
  to?: string;
  children: React.ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  component?: 'a' | 'button' | React.ElementType;
  [key: string]: unknown;
}

const Actionable: React.FC<ActionableProps> = ({
  to,
  children,
  onClick,
  component,
  ...props
}) => {
  /*
   * Configuration checks
   */

  // Throw error if 'to' and 'onClick' props are both present
  if (to && onClick) {
    throw Error(
      'Invalid configuration for the component. Only `to` or `onClick` is allowed, not both together.'
    );
  }

  // Throw error if 'a' doesn't have a 'to' prop
  if (component === 'a' && !to) {
    throw Error(
      'Invalid configuration for the component. It misses the `to` property'
    );
  }

  // Throw error if 'button' doesn't have a 'onClick' prop
  if (component === 'button' && !onClick) {
    throw Error(
      'Invalid configuration for the component. It misses the `onClick` property'
    );
  }

  const propsToComponent = { ...props, children, href: to, onClick, to };

  /*
   * Create a component from scratch
   */

  if (typeof component === 'string') {
    return createElement(component, propsToComponent);
  }

  /*
   * Check if it is a external link, then put all attrs necessaries
   */

  if (typeof to !== 'string' && typeof onClick === undefined) {
    throw Error(
      'Invalid configuration for the component. It misses the `to` property'
    );
  }

  if (typeof to === 'string') {
    if (RegExp(/^(http|https):/g).test(to)) {
      return (
        <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      );
    }

    /**
     * Create a new component based on component from prop
     */

    if (isValidElementType(component)) {
      const Component = component;

      return <Component {...propsToComponent} />;
    }

    /*
     * Return a regular link as default
     */

    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  }

  /*
   * Check if there is onClick function valid, then create a button
   */

  if (typeof onClick !== 'function') {
    throw Error(
      'Invalid configuration for the component. It misses the `onClick` property'
    );
  }

  return (
    <button onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export { Actionable, ActionableProps };
