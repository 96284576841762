/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
} from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

import { IconPlus, Typography } from '../';

// Types

interface AccordionProps {
  children?: React.ReactNode;
  variant: 'light' | 'dark';
}

// Component styles

const useStyles = makeStyles((theme) => {
  const accordionDefaultStyles = {
    '&.Mui-expanded': {
      margin: '0',
    },
    '&:before': {
      display: 'none',
    },
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[0],
    margin: '0px',
  };

  return createStyles({
    AccordionDetails: {
      padding: `0 0px ${theme.spacing(4)}px`,
    },
    AccordionSummary: {
      '&$expanded': {
        margin: 0,
        minHeight: 'unset',
      },
      padding: '0 0px',
    },
    darkAccordion: {
      '& .MuiIconButton-root': {
        '& svg': {
          '& line': {
            stroke: `${theme.palette.primary.main}`,
          },
        },
      },
      ...accordionDefaultStyles,
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    lightAccordion: {
      '& .MuiIconButton-root': {
        '& svg': {
          '& line': {
            stroke: `${theme.palette.secondary.main}`,
          },
        },
      },
      ...accordionDefaultStyles,
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  });
});

const CustomAccordionSummary = withStyles({
  content: {
    '& p': {
      fontWeight: 500,
    },
    '&$expanded': {
      margin: ' 12px 0px',
    },
    margin: ' 12px 0px',
  },
  expandIcon: {
    '& svg .whileOpenRemove': {
      transformOrigin: '10px 10px',
      transition: '0.3s all ease',
    },
    '&$expanded': {
      '& svg .whileOpenRemove': {
        transform: 'rotate(90deg)',
        transformOrigin: '10px 10px',
      },
      margin: '0 -12px 0 0',
      transform: 'rotate(0)',
    },
  },
  expanded: {},
  root: {
    '&$expanded': {
      minHeight: '46px',
    },
    minHeight: '46px',
  },
})(MuiAccordionSummary);

// Accordion Container
const Accordion: React.FC<AccordionProps> = ({ children, variant }, props) => {
  const styles = useStyles();

  const accordionStylesObj: { [key: string]: string } = {
    dark: styles.darkAccordion,
    light: styles.lightAccordion,
  };

  const accordionStyles = (type: string) => accordionStylesObj[type];

  return (
    <MuiAccordion square className={accordionStyles(variant)} {...props}>
      {children}
    </MuiAccordion>
  );
};

// Accordion Description
const AccordionDetails: React.FC<AccordionDetailsProps> = (
  { children },
  props
) => {
  const styles = useStyles();

  return (
    <MuiAccordionDetails className={styles.AccordionDetails} {...props}>
      <div>{children}</div>
    </MuiAccordionDetails>
  );
};

// Accordion Title
const AccordionSummary: React.FC<AccordionSummaryProps> = (
  { children },
  props
) => {
  const styles = useStyles();

  return (
    <CustomAccordionSummary
      className={styles.AccordionSummary}
      expandIcon={<IconPlus />}
      {...props}
    >
      <Typography component="div" variant="body1">
        {children}
      </Typography>
    </CustomAccordionSummary>
  );
};

export { Accordion, AccordionDetails, AccordionSummary };
