/*
 * Copyright (C) 2019 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { SVGProps } from 'react';

const IconDownload: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#FFF" width="24" height="24" rx="3" />
      <path
        fill="#141929"
        fillRule="nonzero"
        d="M6 12.905l1.018-1.048 4.245 4.313V5h1.474v11.17l4.232-4.313L18 12.905 12 19z"
      />
    </g>
  </svg>
);

export { IconDownload };
