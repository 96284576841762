/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import interMediumWoff from './fonts/Inter-Medium.woff';
import interMediumWoff2 from './fonts/Inter-Medium.woff2';
import interRegularWoff from './fonts/Inter-Regular.woff';
import interRegularWoff2 from './fonts/Inter-Regular.woff2';
import interSemiBoldWoff from './fonts/Inter-SemiBold.woff';
import interSemiBoldWoff2 from './fonts/Inter-SemiBold.woff2';
import turbinadoBoldWoff from './fonts/Turbinado-Bold.woff';
import turbinadoBoldWoff2 from './fonts/Turbinado-Bold.woff2';
import { Breakpoints } from 'utils/enums';

const FONT_SIZE_BASE = 16;

const pxToRem = (px: number) => `${px / FONT_SIZE_BASE}rem`;

const inter = { fontFamily: 'Inter, Arial, sans-serif' };
const turbinado = { fontFamily: 'Turbinado, Arial, sans-serif' };

/* eslint-disable sort-keys */
const theme = createTheme();
const typographySettings = {
  fontSize: FONT_SIZE_BASE,

  pxToRem,

  h1: {
    ...inter,
    fontSize: pxToRem(56),
    lineHeight: 1.1,
    letterSpacing: '-1px',
    fontWeight: 600,
  },
  h2: {
    ...inter,
    fontSize: pxToRem(40),
    lineHeight: 1.15,
    letterSpacing: -0.2,
    fontWeight: 500,
  },
  h3: {
    ...inter,
    fontSize: pxToRem(32),
    lineHeight: 1.18,
    letterSpacing: -0.4,
    fontWeight: 500,
  },
  h4: {
    ...inter,
    fontSize: pxToRem(24),
    lineHeight: 1.4,
    letterSpacing: -0.4,
    fontWeight: 500,
  },
  body1: {
    ...inter,
    fontSize: pxToRem(18),
    lineHeight: 1.5,
    letterSpacing: -0.7,
    fontWeight: 400,
  },
  body2: {
    ...inter,
    fontSize: pxToRem(16),
    lineHeight: 1.5,
    letterSpacing: -0.1,
    fontWeight: 400,
  },
  caption: {
    ...inter,
    fontSize: pxToRem(12),
    lineHeight: 1.23,
    letterSpacing: 0.2,
    fontWeight: 400,
  },
  subtitle1: {
    ...turbinado,
    fontSize: '2em',
    lineHeight: 1.1,
    letterSpacing: 1.5,
    fontWeight: 500,

    [theme.breakpoints.up(Breakpoints.Md)]: {
      fontSize: '2.2em',
    },
  },
  subtitle2: {
    ...turbinado,
    fontSize: pxToRem(72),
    lineHeight: 1.1,
    letterSpacing: 1.5,
    fontWeight: 700,
  },
};

const interRegular = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Inter'),
    local('Inter-Regular'),
    url(${interRegularWoff}) format('woff'),
    url(${interRegularWoff2}) format('woff2')
  `,
};

const interMedium = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('Inter'),
    local('Inter-Medium'),
    url(${interMediumWoff}) format('woff'),
    url(${interMediumWoff2}) format('woff2')
  `,
};

const interSemiBold = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('Inter'),
    local('Inter-SemiBold'),
    url(${interSemiBoldWoff}) format('woff'),
    url(${interSemiBoldWoff2}) format('woff2')
  `,
};

const turbinadoBold = {
  fontFamily: 'Turbinado',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Turbinado'),
    local('Turbinado-Bold'),
    url(${turbinadoBoldWoff}) format('woff'),
    url(${turbinadoBoldWoff2}) format('woff2')
  `,
};

const fontFaces = {
  '@font-face': [interRegular, interMedium, interSemiBold, turbinadoBold],
};

export { fontFaces, Typography, typographySettings };
