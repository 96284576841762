/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { makeStyles, styled } from '@material-ui/core/styles';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  HTMLProps,
  useEffect,
  useState,
} from 'react';
import useIsInViewport from 'use-is-in-viewport';

import { Texture } from '..';
import { Typography } from '.';
import { Breakpoints } from 'utils/enums';
import { generateHighlightElements } from 'utils/generateHighlightElements';

const TitleConfig: ForwardRefExoticComponent<any> = forwardRef((props, ref) => {
  return (
    <Typography ref={ref} component="span" variant="subtitle1" {...props} />
  );
});

TitleConfig.displayName = 'TitleConfig';

const CursiveElement = styled(TitleConfig)(({ theme }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    transform: `translateY(${theme.typography.pxToRem(10)})`,
    width: 'max-content',
  };
});

const svgStyle = makeStyles((theme) => ({
  '@keyframes dash': {
    '0%': { width: 0 },
    '100%': { width: 410 },
  },
  root: {
    '& clipPath rect': {
      width: 0,
    },
    '&.texture__animation--on clipPath rect': {
      '@media (prefers-reduced-motion)': {
        animationDelay: '0s',
        animationDuration: '0s',
      },
      animation: '$dash 800ms 1000ms cubic-bezier(0.2, 1, 0.2, 1) forwards',
    },

    bottom: '.26em',
    left: 0,
    position: 'absolute',
    right: 0,
    transform: 'rotate(-.5deg)',
    width: '100%',
  },
  span: {
    [theme.breakpoints.up(Breakpoints.Md)]: {
      display: 'block',
    },
  },
}));

const ElementCursive: React.FC<HTMLProps<Record<string, unknown>>> = ({
  children,
  style,
  ...props
}) => {
  const classes = svgStyle();
  const [isInViewport, targetRef] = useIsInViewport();
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    if (isInViewport) {
      setAnimated(true);
    }
  }, [isInViewport]);

  return (
    <CursiveElement ref={targetRef} className={classes.span} {...props}>
      {children}
      <Texture
        style={style}
        className={clsx(animated && 'texture__animation--on', classes.root)}
      />
    </CursiveElement>
  );
};

const TitleHighlight: React.FC<TypographyProps & { as?: string }> = ({
  as,
  children,
  ...props
}) => {
  const generatedElements =
    typeof children === 'string'
      ? generateHighlightElements(children, ElementCursive)
      : children;

  return (
    // @ts-ignore Due to missing types in Material UI for newer versions of typescript.
    <Typography component={as} {...props}>
      {generatedElements}
    </Typography>
  );
};

export { TitleHighlight };
