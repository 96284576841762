/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme, useTheme } from '@material-ui/core';
import React, {
  Children,
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isValidElementType } from 'react-is';

import { CarouselDot, CarouselDotContainer } from './DotsElements';
import { Breakpoints } from 'utils/enums';

// Types
export interface CarouselProps {
  autoPlay?: boolean;
  children?: React.ReactNode;
  dots?: boolean;
  mobileFullWidth?: boolean;
  speed?: number;
  vertical?: boolean;
  renderDot?: RenderDotFunc;
  renderDotsContainer?: React.ElementType<{ children?: React.ReactNode }>;
}

export interface CarouselRenderDotProps {
  index: number;
  isActive: boolean;
  goTo: (index: number) => void;
}

type RenderDotFunc = ({
  index,
  isActive,
  goTo,
}: CarouselRenderDotProps) => React.ReactNode;

/*
 * Elements
 */

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& > div': {
      flexDirection: 'row',
    },
    marginTop: 0,
  },
}));

const Wrapper = styled('div')({
  flexShrink: 0.6,
  maxHeight: '100vmax',
  maxWidth: '100vmax',
  overflow: 'hidden',
});

const Item = styled('div')({
  minHeight: '100%',
  minWidth: '100%',
  overflow: 'hidden',
  position: 'relative',
});

const Holder = styled(({ vertical: _, ...p }) => <div {...p} />)<
  Theme,
  { vertical: boolean }
>(({ theme, vertical }) => ({
  display: 'flex',
  flexDirection: vertical ? 'column' : 'row',
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.easeInOut,
  }),
  width: '100%',
}));

/*
 * Defaults
 */

const renderDotDefault = ({
  index,
  isActive,
  goTo,
}: CarouselRenderDotProps) => {
  if (isActive) {
    return index + 1;
  }

  return <button onClick={() => goTo(index)}>{index + 1}</button>;
};

const renderDotsContainerDefault: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => <div>{children}</div>;

/*
 * Main
 */

const Carousel: React.FC<CarouselProps> = ({
  autoPlay = true,
  children,
  dots = false,
  speed = 5000,
  vertical = false,
  mobileFullWidth = false,
  renderDot = renderDotDefault,
  renderDotsContainer = renderDotsContainerDefault,
  ...props
}) => {
  /*
   * States
   */

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperSize, setWrapperSize] = useState({ height: 0, width: 0 });
  const [currentIndex, setCurrentIndex] = useState(0);

  // it should be flex and row, to calculated the values of width and height right
  const [style, setStyle] = useState<CSSProperties>({
    display: 'flex',
    flexDirection: 'row',
  });

  const childrenLength = Children.count(children);
  const theme = useTheme();

  /*
   * Dots
   */

  const goTo = (index: number) => setCurrentIndex(index);

  const renderDotsSection = () => {
    if (!dots) {
      return null;
    }

    if (isValidElementType(renderDotsContainer)) {
      const DotsContainer = renderDotsContainer;

      return (
        <DotsContainer>
          {Array(childrenLength)
            .fill(' ')
            .map((_, index) =>
              renderDot({ goTo, index, isActive: index === currentIndex })
            )}
        </DotsContainer>
      );
    }

    return;
  };

  /*
   * Calculates sizes
   */

  const updateSize = () => {
    const bounds = wrapperRef?.current?.getBoundingClientRect();

    if (bounds) {
      setWrapperSize({ height: bounds.height, width: bounds.width });
    }
  };

  /*
   * Autoplay
   */

  useEffect(() => {
    if (!autoPlay) {
      return;
    }

    const timer = setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % childrenLength);
    }, speed);

    return () => clearTimeout(timer);
  }, [autoPlay, childrenLength, currentIndex, speed]);

  /*
   * Defines style
   */

  useEffect(() => {
    updateSize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  useEffect(() => {
    const styleHorizontal = {
      transform: `translateX(-${currentIndex * wrapperSize.width}px)`,
    };

    const styleVertical = {
      transform: `translateY(-${currentIndex * wrapperSize.height}px)`,
    };

    setStyle({
      ...(vertical ? styleVertical : styleHorizontal),
      height: `${wrapperSize.height}px`,
      width: mobileFullWidth ? '100%' : `${wrapperSize.width}px`,

      [theme.breakpoints.up(Breakpoints.Md)]: {
        width: `${wrapperSize.width}px`,
      },
    });
  }, [currentIndex, mobileFullWidth, theme.breakpoints, vertical, wrapperSize]);

  return (
    <Container {...props}>
      <Wrapper ref={wrapperRef}>
        <Holder vertical={vertical} style={style}>
          {Children.map(children, (child, index) => {
            return (
              <Item
                className={
                  currentIndex === index ? 'current-item' : 'hidden-item'
                }
                key={index}
              >
                {child}
              </Item>
            );
          })}
        </Holder>
      </Wrapper>

      {renderDotsSection()}
    </Container>
  );
};

export { Carousel, CarouselDot, CarouselDotContainer };
