/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { SVGProps } from 'react';

const IconSearch: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width={16} height={18} {...props}>
    <g
      transform="translate(1 1.5)"
      stroke="#141929"
      strokeWidth="1.4"
      fill="none"
      fillRule="evenodd"
    >
      <circle cx="6" cy="6" r="6" />
      <path d="M9.75 10.75l4.5 4.5" />
    </g>
  </svg>
);

export { IconSearch };
