/*
 * Copyright (C) 2022 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ApolloProvider } from '@apollo/client';
import React from 'react';

import client from './client';

const ApolloWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => <ApolloProvider client={client}>{children}</ApolloProvider>;

export { ApolloWrapper };
