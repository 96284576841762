/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import React from 'react';

import { Actionable, ActionableProps } from '..';
import { IconDownload } from '../Icons';

// Types
type DownloadableProps = ActionableProps;

const DownloadableHolder = styled(Actionable)(({ theme }) => ({
  bottom: theme.typography.pxToRem(8),
  position: 'absolute',
  right: theme.typography.pxToRem(8),
}));

const Downloadable: React.FC<DownloadableProps> = ({ to, ...props }) => {
  return (
    <DownloadableHolder to={to} download {...props}>
      <IconDownload />
    </DownloadableHolder>
  );
};

export { Downloadable };
