/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createStyles, makeStyles, styled } from '@material-ui/core';
import clsx from 'clsx';
import React, { Suspense, useState } from 'react';
import { SingleDatePickerShape } from 'react-dates';

import calendarSrc from 'assets/calendar.png';
import { Typography } from 'components/UI';
import { Breakpoints } from 'utils/enums';

// Split in more than one module, reducing the main bundle
const SingleDatePicker = React.lazy(() => import('./ReactDateExport'));

// Types
type DateProps = Partial<SingleDatePickerShape> & {
  // Due to the lack of a correct react-dates documentation, there's no easy way to specify this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: any;
  // Due to the lack of a correct react-dates documentation, there's no easy way to specify this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: React.SetStateAction<any>;
  className?: string;
  placeholder?: string;
  errorMessage?: string;
};

// Styles

const useDateStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      height: theme.spacing(5),
      width: theme.spacing(5),
    },
    loading: {
      color: theme.palette.grey[600],
      fontWeight: 500,
      margin: 0,
      padding: '0.45em 0.7em',
    },
    root: {
      '& .CalendarDay__blocked_out_of_range, & .CalendarDay__blocked_calendar':
        {
          color: theme.palette.grey[300],
        },

      '& .CalendarDay__default': {
        background: 'transparent',
        border: 0,
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.easeInOut,
        }),
      },

      '& .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover, .CalendarDay__default:hover':
        {
          background: theme.palette.secondary.light,
          borderRadius: '50%',
          color: theme.palette.primary.main,
        },

      '& .CalendarMonth, .CalendarMonthGrid': {
        background: 'transparent',
      },
      '& .CalendarMonth_caption': {
        '& strong': {
          fontWeight: 500,
        },
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(15),
        letterSpacing: theme.typography.pxToRem(-0.24),
        paddingTop: theme.spacing(6.75),
      },

      '& .DateInput': { width: '100%' },

      '& .DateInput_fang': {
        display: 'none',
      },

      '& .DateInput_input': {
        borderBottom: 'none !important',
        color: theme.palette.primary.dark,
        fontFamily: 'Inter',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        letterSpacing: theme.typography.pxToRem(-0.36),
        lineHeight: theme.typography.pxToRem(18),
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.easeIn,
        }),
        width: '100%',
      },

      '& .DateInput_input__focused': {
        borderBottom: 'none',
      },

      '& .DayPicker': {
        backdropFilter: 'saturate(180%) blur(20px);',
        background: 'rgba(255,255,255,.9)',
        width: '100%',
      },

      '& .DayPickerNavigation_button__default': {
        background: 'transparent',
        border: '0',
        outline: '0',
        transform: 'scale(0.8)',
      },

      '& .DayPickerNavigation_svg__horizontal': {
        fill: theme.palette.primary.main,
      },

      '& .DayPicker__withBorder': {
        borderRadius: theme.spacing(2),
      },

      '& .DayPicker_weekHeader_li': {
        textTransform: 'uppercase',
      },

      '& .SingleDatePicker': {},

      '& .SingleDatePickerInput': {
        width: '100%',
      },

      '& .SingleDatePickerInput__withBorder': {
        border: '0',
        maxWidth: theme.typography.pxToRem(330),
        width: '100%',
      },

      '& .SingleDatePicker_picker, .SingleDatePicker': {
        width: '100%',
      },

      '& .SingleDatePicker_picker__directionLeft': {
        background: 'transparent',
        borderRadius: theme.spacing(2),
        marginTop: '-15px',
        [theme.breakpoints.down(Breakpoints.Md)]: {
          marginLeft: '-38px',
        },
      },

      alignItems: 'center',
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: theme.borderRadius[1],
      display: 'flex',
      padding: theme.spacing(1, 2, 1, 3),
      [theme.breakpoints.up(Breakpoints.Md)]: {
        padding: theme.spacing(2, 2, 2, 3),
      },
    },
    /*
     * The style priority is determined by the order in which they are defined.
     * Since this color needs to have higher priority than the default, defined
     * in 'root', it must come after, even though eslint wants it sorted before.
     */
    // eslint-disable-next-line sort-keys
    error: {
      borderColor: theme.palette.error.main,
    },
  })
);

const ErrorMessage = styled(Typography)(({ theme }) => ({
  bottom: theme.typography.pxToRem(12),
  color: theme.palette.brandRed500,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  marginTop: theme.spacing(2),
}));

const FormDate: React.FC<DateProps> = ({
  date,
  onChange,
  className,
  placeholder,
  errorMessage,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const classes = useDateStyles();

  const isSSR = typeof window === 'undefined';

  return (
    <>
      <div
        className={clsx(
          classes.root,
          className,
          !!errorMessage && classes.error
        )}
      >
        <img className={classes.icon} src={calendarSrc} alt="presentation" />

        {!isSSR && (
          <Suspense fallback={<p className={classes.loading}>Loading...</p>}>
            <SingleDatePicker
              id="departure"
              numberOfMonths={1}
              onDateChange={(date) => onChange({ target: { value: date } })}
              // Due to the lack of a correct react-dates documentation, there's no easy way to specify this type
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onFocusChange={({ focused }: any) => setFocused(focused)}
              focused={focused}
              date={date}
              placeholder={placeholder}
              displayFormat="MM/DD/YYYY"
              hideKeyboardShortcutsPanel
              firstDayOfWeek={1}
              noBorder
              {...props}
            />
          </Suspense>
        )}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export { FormDate };
