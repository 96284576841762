/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme } from '@material-ui/core';
import React from 'react';

import { hexToRgba } from '../../../utils/hexToRgb';

// Types

export interface CardProps {
  bgColor?: string;
  shadowColor?: string;
  children?: React.ReactNode;
  color?: string;
  ticket?: boolean;
  img?: {
    source?: string;
    position?: string;
    size?: 'cover' | 'contain' | number;
  };
}

const StyledCard = styled(
  ({ color: _c, bgColor: _b, shadowColor: _s, img: _i, ticket: _t, ...p }) => (
    <div {...p} />
  )
)<Theme, CardProps>(
  ({
    theme,
    bgColor = theme.palette.common.white,
    color = theme.palette.primary.dark,
    shadowColor = bgColor,
    img = null,
    ticket = false,
  }) => {
    const backgroundSizeValue =
      typeof img?.size === 'number' ? `${img.size}px` : `${img?.size}`;

    const getImage = { backgroundImage: `url(${img?.source})` };
    const getSize = { backgroundSize: `${backgroundSizeValue}` };
    const getBgPosition = { backgroundPosition: `${img?.position}` };

    return {
      backgroundColor: `${bgColor}`,
      backgroundRepeat: 'no-repeat',
      borderRadius: ticket ? theme.borderRadius[2] : theme.borderRadius[1],
      boxShadow: `0 10px 20px ${hexToRgba(shadowColor, 0.4)}`,
      color: `${color}`,
      display: 'block',
      height: '100%',
      padding: theme.spacing(10),

      // variants
      ...(img?.source ? getImage : {}),
      ...(img?.size ? getSize : { backgroundSize: 'cover' }),
      ...(img?.position
        ? getBgPosition
        : { backgroundPosition: 'center center' }),
    };
  }
);

const Card: React.FC<CardProps> = ({
  bgColor,
  shadowColor,
  children,
  color,
  img,
  ticket,
  ...props
}) => {
  return (
    <StyledCard
      img={img}
      color={color}
      bgColor={bgColor}
      shadowColor={shadowColor}
      ticket={ticket}
      {...props}
    >
      {children}
    </StyledCard>
  );
};

export { Card };
