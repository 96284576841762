import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

import { TERMINAL_GRAPH_API_URL } from 'utils/constants';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    fetch,
    uri: TERMINAL_GRAPH_API_URL,
  }),
});

export default client;
