/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme } from '@material-ui/core/styles';
import React from 'react';
import {
  AlertTemplateProps,
  AlertType,
  positions,
  Provider,
  transitions,
  useAlert,
  withAlert,
} from 'react-alert';

import { IconClose, Typography } from '../';

// Types

type WrapperProp = { type: AlertType };
type BackgroundParams = { theme: Theme } & WrapperProp;

const WrapperAlert = styled('div')<Theme, WrapperProp>(({ theme, type }) => {
  const setBackgroundAlert = ({ theme, type }: BackgroundParams) => {
    if (type === 'error') {
      return theme.palette.brandRed500;
    }

    return theme.palette.primary.main;
  };

  const bgColor = setBackgroundAlert({ theme, type });
  const fbColor = theme.palette.getContrastText(bgColor);

  return {
    background: bgColor,
    borderRadius: theme.borderRadius[0],
    color: fbColor,
    padding: theme.spacing(2.3, 10, 2.3, 5),
    position: 'relative',
    width: 'calc(100vw - 1em)',
  };
});

const CloseButton = styled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(4),
}));

const AlertTemplate: React.FC<AlertTemplateProps> = ({
  message,
  style,
  close,
  options: { type },
}) => {
  return (
    /*
     * Styled from Material-UI is turning this prop as optional, but it isn't
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <WrapperAlert type={type!} style={style}>
      <Typography align="center">{message}</Typography>
      <CloseButton onClick={close}>
        <IconClose />
      </CloseButton>
    </WrapperAlert>
  );
};

const AlertProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    transition: transitions.FADE,
  };

  return (
    <Provider template={AlertTemplate} {...options}>
      {children}
    </Provider>
  );
};

export { AlertProvider, useAlert, withAlert };
