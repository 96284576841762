/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Grid, makeStyles } from '@material-ui/core';
import MuiContainer from '@material-ui/core/Container';
import { GridProps } from '@material-ui/core/Grid/Grid';
import clsx from 'clsx';
import React from 'react';

import { Breakpoints } from 'utils/enums';

// Styles
const useContainerStyle = makeStyles((theme) => ({
  root: {
    maxWidth: theme.typography.pxToRem(728),
    padding: theme.spacing(0, 5),

    [theme.breakpoints.up(Breakpoints.Md)]: {
      maxWidth: theme.typography.pxToRem(1440),
      padding: theme.spacing(0, 15),
    },
  },
}));

const Container: React.FC<GridProps> = ({ children, className, ...props }) => {
  const classes = useContainerStyle();

  return (
    <MuiContainer className={clsx(classes.root, className)} fixed {...props}>
      <Grid container spacing={8}>
        {children}
      </Grid>
    </MuiContainer>
  );
};

const Col: React.FC<GridProps> = ({ children, ...props }) => {
  return (
    <Grid item {...props}>
      {children}
    </Grid>
  );
};

export { Col, Container };
