/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { SVGProps } from 'react';

const IconClose: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" {...props}>
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#FFF"
      strokeWidth="1.4"
      d="M1 1l11.314 11.314m0-11.314L1 12.314"
    ></path>
  </svg>
);

export { IconClose };
