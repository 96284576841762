/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import React from 'react';

export interface IllustrationProps extends GatsbyImageProps {
  asset?: string;
  assetAspectRatio?: number;
}

const styles = makeStyles((theme) => ({
  '@keyframes moveX': {
    '0%': { backgroundPositionX: 0 },
    '100%': { backgroundPositionX: '200%' },
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  illustration: {
    '@media (prefers-reduced-motion)': {
      animation: 'none',
    },
    animation: '$moveX 300ms steps(2) infinite',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '200% auto',
    width: '100%',
  },
  illustrationWrap: {
    position: 'absolute',
    width: '100%',
    zIndex: 9,
  },
  mainImage: {
    borderRadius: theme.borderRadius[1],
    overflow: 'hidden',
  },
}));

const Illustration: React.FC<IllustrationProps> = ({
  className,
  image,
  style,
  asset,
  assetAspectRatio,
  onLoad,
  ...props
}) => {
  const { container, mainImage, illustration, illustrationWrap } = styles();

  props.alt = props.alt ?? '';

  return (
    <div className={clsx(container, className)} style={style}>
      {asset && (
        <div className={clsx(illustrationWrap, 'asset')}>
          <div
            className={illustration}
            onLoad={() => onLoad?.({ wasCached: false })}
            style={{
              backgroundImage: `url("${asset}")`,
              paddingTop: `${(100 / (assetAspectRatio ?? 0)) * 2}%`,
            }}
            {...props}
          />
        </div>
      )}
      <GatsbyImage
        className={clsx(mainImage, 'main-image')}
        image={image}
        {...props}
      />
    </div>
  );
};

export { Illustration };
