/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { InputLabel, Theme } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

// Types
interface CheckBoxProps {
  checked: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  sideLabel?: React.ReactNode;
  type?: 'text' | 'email' | 'password';
}

// Styles
const useCheckboxStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      margin: theme.spacing(0, 0.75, 0, 0),
    },
    label: {
      '& a': {
        color: theme.palette.primary.main,
      },
      color: theme.palette.secondary.dark,
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: theme.typography.pxToRem(-0.36),
      lineHeight: theme.typography.pxToRem(20),
      position: 'relative',
      transform: 'unset',
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      transform: `translateX(${theme.typography.pxToRem(-9)})`,
    },
  })
);

const FormCheckbox: React.FC<CheckBoxProps> = ({
  children,
  name,
  error = false,
  checked,
  disabled = false,
  onChange,
  className,
  ...props
}) => {
  const classes = useCheckboxStyles();
  return (
    <div className={clsx(classes.root, className)} {...props}>
      <Checkbox
        className={clsx(classes.checkbox, className)}
        name={name}
        id={name}
        color="primary"
        checked={checked}
        onChange={onChange}
        value={name}
        disabled={disabled}
      />
      <InputLabel
        className={clsx(classes.label, className)}
        shrink={false}
        htmlFor={name}
        error={error}
      >
        {children}
      </InputLabel>
    </div>
  );
};

export { FormCheckbox };
