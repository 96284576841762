/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createElement, HTMLProps } from 'react';

const generateHighlightElements = (
  copy: string,
  Element: React.FC<HTMLProps<Record<string, unknown>>>
): React.ReactNode => {
  const matchWithoutCapture = new RegExp(
    /(\[cursive(?: leftOffset="[^[]+")?\][^[]+\[\/cursive\])/gm
  );
  const matchWithCapture = new RegExp(
    /\[cursive( leftOffset="([^[]+)")?\]([^[]+)\[\/cursive\]/gm
  );
  const splitContent = copy.split(matchWithoutCapture);

  return splitContent.map((item, index) => {
    const content = matchWithCapture.exec(item);

    if (Array.isArray(content)) {
      const [, , leftOffset = 0, text = ''] = content;

      return createElement(
        Element,
        { key: index, style: { left: leftOffset } },
        text
      );
    }

    return item;
  });
};

export { generateHighlightElements };
