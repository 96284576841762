/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createTheme } from '@material-ui/core/styles';

import { fontFaces, typographySettings } from '../Typography/Typography';
import { Breakpoints } from 'utils/enums';

/* eslint-disable sort-keys */

const palette = {
  background: {
    default: '#E6F1F5',
    paper: '#F7F8FA',
  },
  error: {
    main: '#F77275',
  },
  primary: {
    dark: '#141929',
    main: '#171F45',
    light: '#67A4C0',
  },
  secondary: {
    dark: '#7C848E',
    main: '#CECFD4',
    light: '#E4E7E9',
  },
  text: {
    secondary: '#898C94',
  },

  /*
   * Brand values
   */
  brand100: '#E6F1F5',
  brand500: '#7CC7E6',
  brand600: '#67A4C0',
  brand800: '#171F45',
  brand900: '#141929',

  brandGreen300: '#7FD0B7',
  brandGreen500: '#6BD6B2',

  brandRed500: '#F77275',
  brandYellow100: '#FFE9CC',
  brandYellow500: '#FFC67B',
};

const theme = createTheme({
  breakpoints: {
    hover: '@media(hover: hover)',
  },

  palette: {
    ...palette,
    text: {
      ...palette.text,
      disabled: palette.primary.light,
      hint: palette.primary.light,
      primary: palette.primary.main,
    },
  },

  /*
   * Resetting `shadows` value, as style guide doesn't use it
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shadows: Array(25).fill('none') as any,
  spacing: 4,
  typography: typographySettings,
});

const customTheme = {
  ...theme,
  borderRadius: [3, 8, 16, 24, 50],

  /*
   * Material-UI has its own shadow option,
   * But as style guide needs only one value
   * it makes more sense create a new key with a string
   * instead of create an array with 25 equals values
   */

  boxShadow: [
    '0 5px 11px 0 rgba(20,25,41,0)',
    '0 5px 11px 0 rgba(20,25,41,0.2)',
  ],
  overrides: {
    MuiCheckbox: {
      checked: {
        '& svg': {
          '& path': {
            d: 'path("M21.3333333,0 L2.66666667,0 C1.18666667,0 0,1.2 0,2.66666667 L0,21.3333333 C0,22.8 1.18666667,24 2.66666667,24 L21.3333333,24 C22.8133333,24 24,22.8 24,21.3333333 L24,2.66666667 C24,1.2 22.8133333,0 21.3333333,0 Z M9.33333333,18.6666667 L2.66666667,12 L4.54666667,10.12 L9.33333333,14.8933333 L19.4533333,4.77333333 L21.3333333,6.66666667 L9.33333333,18.6666667 Z")',
          },
        },
      },
      root: {
        '& svg': {
          '& path': {
            d: 'path("M21.3333333,0 C22.8,0 24,1.2 24,2.66666667 L24,2.66666667 L24,21.3333333 C24,22.8 22.8,24 21.3333333,24 L21.3333333,24 L2.66666667,24 C1.2,24 0,22.8 0,21.3333333 L0,21.3333333 L0,2.66666667 C0,2.38066251 0.0456313262,2.10479865 0.129973127,1.84599593 C0.478147096,0.777624525 1.48600416,0 2.66666667,0 L2.66666667,0 Z M21.6666667,1.33333333 L2.33333333,1.33333333 C1.78104858,1.33333333 1.33333333,1.78104858 1.33333333,2.33333333 L1.33333333,2.33333333 L1.33333333,21.6666667 C1.33333333,22.2189514 1.78104858,22.6666667 2.33333333,22.6666667 L2.33333333,22.6666667 L21.6666667,22.6666667 C22.2189514,22.6666667 22.6666667,22.2189514 22.6666667,21.6666667 L22.6666667,21.6666667 L22.6666667,2.33333333 C22.6666667,1.78104858 22.2189514,1.33333333 21.6666667,1.33333333 L21.6666667,1.33333333 Z")',
          },
          fontSize: '1.25rem',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        ...fontFaces,
        body: {
          color: '#000',
          background: 'linear-gradient(45deg, #E6F1F5 10%, #E6F1F5 100%)',
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiGrid: {
      'spacing-xs-8': {
        '& > $item': {
          padding: 0,
        },

        margin: '0',
        width: '100%',
      },
      [theme.breakpoints.up(Breakpoints.Md)]: {
        'spacing-xs-8': {
          '& > $item': {
            '&:last-child': {
              padding: 0,
            },
            padding: theme.spacing(0, 6, 0, 0),
          },
        },
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: theme.spacing(3.75),
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
          0
        )} ${theme.typography.pxToRem(9)}`,
      },
    },
  },
  palette: theme.palette,
};

export default customTheme;
