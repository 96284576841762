/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';

import { Actionable } from '../Actionable';
import { Breakpoints } from 'utils/enums';

// Types

interface ButtonProps {
  variant?: 'labeled' | 'regular';
  disabled?: boolean;
  fullWidth?: boolean;
  mode?: 'light' | 'dark';
}

const Button = styled(Actionable)<Theme, ButtonProps>(
  ({
    fullWidth = false,
    disabled = false,
    theme,
    variant = 'regular',
    mode = 'light',
  }) => {
    // Colors
    const isLightMode = mode === 'light';
    const white = theme.palette.common.white;
    const primaryColor = theme.palette.primary.main;

    // Conditional colors
    const defaultColor = isLightMode ? primaryColor : white;
    const defaultColorHover = isLightMode ? `${primaryColor}cc` : `${white}99`;

    // Styles
    const transition = theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
    });

    const dashedLine = `linear-gradient(90deg, ${defaultColor}, ${defaultColor} 75%, transparent 75%, transparent 100%)`;

    const disabledButtonStyle: CreateCSSProperties = {
      [theme.breakpoints.hover]: {
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.borderRadius[0],
      boxShadow: theme.boxShadow[0],
      color: white,
      cursor: 'not-allowed',
      outline: 'none',
    };

    const defaultButtonStyle: CreateCSSProperties = {
      [theme.breakpoints.hover]: {
        '&:hover': {
          backgroundColor: defaultColorHover,
        },
      },
    };

    const labeledButtonStyle: CreateCSSProperties = {
      '&:after': {
        backgroundColor: defaultColor,
        borderRadius: theme.spacing(0, 0, 1, 1),
        content: '""',
        display: 'block',
        height: theme.spacing(7),
        left: 0,
        position: 'absolute',
        top: `calc(-${theme.spacing(7)}px - 1px)`,
        transition,
        width: '100%',
      },
      '&:before': {
        backgroundImage: dashedLine,
        backgroundSize: '10px 5px',
        content: "''",
        display: 'block',
        height: 1,
        left: 3,
        position: 'absolute',
        right: 2,
        top: -1,
        transition,
      },
      [theme.breakpoints.hover]: {
        '&:hover': {
          transform: 'translateY(6px)',
        },
      },

      borderRadius: theme.spacing(1, 1, 2, 2),
      fontWeight: 500,
      marginTop: theme.spacing(5),
      outline: 'none',
      padding: `${theme.spacing(3.25)}px  ${theme.spacing(6)}px`,
      transition,
    };

    return {
      '&, & > *': {
        lineHeight: 0.9,
        [theme.breakpoints.up(Breakpoints.Md)]: {
          lineHeight: 1.3,
        },
      },

      // Default style
      backgroundColor: defaultColor,
      border: 0,
      borderRadius: theme.borderRadius[1],
      boxShadow: theme.boxShadow[1],
      color: isLightMode ? white : primaryColor,
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'center',
      outline: 'none',
      padding: theme.spacing(4.5, 9),
      position: 'relative',
      textDecoration: 'none',
      transition,
      whiteSpace: 'nowrap',
      width: fullWidth ? '100%' : 'auto',

      // Variants
      ...theme.typography.body2,
      ...(disabled ? disabledButtonStyle : {}),
      ...(variant === 'labeled' ? labeledButtonStyle : defaultButtonStyle),
    };
  }
);

export { Button };
