/*
 * Copyright (C) 2019 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import React from 'react';

import { ActionableProps } from '../Actionable';
import { Wrapper } from '../Wrapper';
import { Downloadable } from './Downloadable';

// Types
type DownloadableAreaProps = ActionableProps;

// Components
const DownloadableArea = styled(Wrapper)(({ theme }) => ({
  '&:hover': {
    opacity: '1',
  },
  backgroundColor: `${theme.palette.primary.main}80`,
  height: '100%',
  opacity: '0',
  overflow: 'hidden',
  position: 'relative',
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
  }),
}));

const DownloadableWrapper: React.FC<DownloadableAreaProps> = ({
  to,
  children,
}) => {
  return (
    <DownloadableArea>
      <Downloadable to={to}>{children}</Downloadable>
    </DownloadableArea>
  );
};

export { DownloadableWrapper };
