/*
 * Copyright (C) 2021 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import 'whatwg-fetch';

import { createInstance, ReactSDKClient } from '@optimizely/react-sdk';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { OPTIMIZELY_SDK_KEY } from './utils/constants';

// Create context
const OptimizelyClientContext = createContext<ReactSDKClient | undefined>(
  undefined
);

/**
 * Provides the Optimizely client to consumers via the useOptimizelyClient hook.
 * Asynchronously initializes the client
 *
 * @param { children } - the elements to wrap
 * @return {*}
 */
const OptimizelyProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [client, setClient] = useState<ReactSDKClient>();

  useEffect(() => {
    fetch(`https://cdn.optimizely.com/datafiles/${OPTIMIZELY_SDK_KEY}.json`)
      .then((r) => r.json())
      .then((datafile) => {
        const optimizelyClient = createInstance({
          datafile,
        });
        setClient(optimizelyClient);
      })
      .catch((e) => console.error('Unable to set up Optimizely:', e));
  }, []);

  return (
    <OptimizelyClientContext.Provider value={client}>
      {children}
    </OptimizelyClientContext.Provider>
  );
};

/**
 * Returns the Optimizely client. May be undefined.
 */
const useOptimizelyClient = () =>
  useContext<ReactSDKClient | undefined>(OptimizelyClientContext);

/**
 * Gets whether an Optimizely feature is enabled.
 *
 * @param {string} featureKey - the Optimizely feature key to use
 * @param {string} [userId] - the userId to use. Defaults to 'defaultUserId'
 * @return {boolean} - true if the feature is enabled, false otherwise.
 */
const useOptimizelyDecision = (featureKey: string, userId?: string) => {
  const optimizelyClient = useOptimizelyClient();
  if (!optimizelyClient) {
    return false;
  }
  return optimizelyClient.decide(featureKey, [], userId || 'defaultUserId')
    .enabled;
};

export { OptimizelyProvider, useOptimizelyClient, useOptimizelyDecision };
