/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createStyles, makeStyles, styled, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import MaskedInput from 'react-text-mask';

import { Typography } from 'components/UI';

// Types
interface InputProps {
  adornment?: React.ReactNode;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  errorMessage?: string;
  inputProps?: any;
  label?: string;
  mask?: (string | RegExp)[];
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
  placeholder?: string;
  ref?: string;
  type?: 'text' | 'email' | 'password' | 'number';
  value?: string | number;
}

// Styles
const Field = styled(({ error: _, ...p }) => <div {...p} />)<
  Theme,
  { error: boolean }
>(({ theme, error }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  border: error
    ? `1px solid ${theme.palette.brandRed500}`
    : `1px solid ${theme.palette.secondary.light}`,
  borderRadius: theme.borderRadius[1],
  display: 'flex',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2, 2, 2, 3),
  textAlign: 'left',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeInOut,
  }),
}));

const FormCaption = styled('label')<Theme, { htmlFor: string }>(
  ({ theme }) => ({
    color: theme.palette.text.secondary,
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(12),
    minWidth: '100%',
  })
);

const Flex = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
});

const ErrorMessage = styled(Typography)(({ theme }) => ({
  bottom: theme.typography.pxToRem(12),
  color: theme.palette.brandRed500,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(-2),
}));

const useInputStyle = makeStyles((theme) =>
  createStyles({
    adornment: {
      marginLeft: theme.spacing(1),
    },
    root: {
      border: 0,
      flex: 1,
      font: 'inherit',
      outline: 0,
    },
  })
);

const BaseFormInput: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    adornment,
    disabled,
    label,
    name,
    placeholder = '',
    type = 'text',
    className,
    children,
    inputProps,
    value,
    mask,
    errorMessage,
    onChange,
    onSubmit,
    ...props
  },
  ref
) => {
  const classes = useInputStyle();
  const fieldProps = {
    className: clsx(classes.root, adornment && classes.adornment),
    disabled,
    id: name,
    mask,
    name,
    onChange,
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && onSubmit) {
        e.preventDefault();
        onSubmit();
      }
    },
    placeholder,
    ref,
    type,
    value,
    ...inputProps,
  };

  return (
    <>
      <Field error={!!errorMessage} className={className} {...props}>
        <Flex>
          {label && <FormCaption htmlFor={name}>{label}</FormCaption>}
          <Flex style={{ flexWrap: 'nowrap' }}>
            {adornment}
            {mask ? <MaskedInput {...fieldProps} /> : <input {...fieldProps} />}
          </Flex>
        </Flex>

        {children}
      </Field>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

const FormInput = forwardRef(BaseFormInput);

export { FormInput };
