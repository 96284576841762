/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme, Typography } from '@material-ui/core';
import React from 'react';

import { Actionable, ActionableProps, IconArrow } from '../';

// Types

interface DisabledProp {
  disabled?: boolean;
  withoutHover?: boolean;
}

interface LinkProps extends DisabledProp, ActionableProps {
  withoutHover?: boolean;
}

const Arrow = styled(IconArrow)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const disabledStyle = { opacity: 0.2 };
const hoverStyle = { '&:hover': { opacity: 0.4 } };

const LinkHolder = styled(({ withoutHover: _, ...p }) => <Actionable {...p} />)<
  Theme,
  DisabledProp
>(({ theme, disabled, withoutHover }) => {
  const stylesHandler = () => {
    if (disabled) {
      return disabledStyle;
    }
    if (withoutHover) {
      return { opacity: '1' };
    }

    return hoverStyle;
  };

  return {
    ...stylesHandler,
    color: 'inherit',
    textDecoration: 'none',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
    }),
    whiteSpace: 'nowrap',
  };
});

const Link: React.FC<LinkProps> = ({
  to,
  children,
  disabled,
  withoutHover,
  ...props
}) => {
  return (
    <LinkHolder
      to={to}
      disabled={disabled}
      component={disabled ? 'div' : null}
      withoutHover={withoutHover}
      {...props}
    >
      <Typography component="span">{children}</Typography>
      <Arrow />
    </LinkHolder>
  );
};

export { Arrow, Link };
